import React, { useState } from 'react';
import { useLocation } from "@reach/router";
import { getPageData } from '@/data/pageData';
import { useSiteMetadata } from "@/hooks/use-site-metadata";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "@/components/ui/menubar"

import { ChevronDownIcon } from "@/components/icons/icons";
import { menuItems } from "@/data/menuItems";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "lucide-react";
import LangSelector from "../translation/LangSelector";
import config from '@/utils/config';

export default function NavBar() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const { description } = useSiteMetadata();

  const location = useLocation();
  const { heading, pageDesc } = getPageData(location.pathname);

  const isHomePage = location.pathname === '/';

  const handleToggleDropdown = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(null);
  };

  const toggleSheet = () => {
    setIsSheetOpen(!isSheetOpen);
  };

  return (
    <section className="relative z-30">
      <div className="relative container mx-auto flex items-start justify-between px-6">
        
        {/* Logo */}
        <div className="bg-white p-6 rounded-b-3xl pt-10 lg:pt-20 mb-10">
          <Link className="flex items-center" to="/">
            <img
              src="/images/BBS2025-badge-pos.png"
              alt="BBS 2025 Logo"
              width={250}
              className="xl:w-[250px] lg:w-[200px] w-[150px]"
            />
          </Link>
        </div>

        <div className="flex flex-col justify-between self-end xl:self-normal">
          {/* Desktop Navbar */}
          <nav className="hidden xl:flex space-x-4 relative my-8 py-8 justify-end">
            <Menubar className="flex space-x-1">
              {menuItems.header.map((item, index) => (
                <MenubarMenu key={index}>
                  {item.dropdown ? (
                    <>
                      <MenubarTrigger className="flex items-center hover:text-blue-500 cursor-pointer">
                        {item.label}
                        <ChevronDownIcon className="h-4 w-4 ml-1" />
                      </MenubarTrigger>
                      <MenubarContent className="absolute mt-2 w-48 origin-top-right rounded-md p-1 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {item.items.map((subItem, subIndex) => (
                          <MenubarItem key={subIndex} onSelect={handleCloseDropdown}>
                            <Link
                              to={subItem.to}
                              className="w-full p-2 stagger"
                              style={{ animationDelay: `${index * 50}ms` }}
                            >
                              {subItem.label}
                            </Link>
                          </MenubarItem>
                        ))}
                      </MenubarContent>
                    </>
                  ) : (
                    <MenubarMenu>
                      <MenubarTrigger asChild>
                        <Link
                          to={item.to}
                          className="hover:text-blue-500 cursor-pointer flex items-center"
                        >
                          {item.label}
                        </Link>
                      </MenubarTrigger>
                    </MenubarMenu>
                  )}
                </MenubarMenu>
              ))}
              {config.languageSelector && <LangSelector />}
            </Menubar>
          </nav>
          {/* Mobile Navbar */}
          <nav className="xl:hidden flex items-center bg-c1 p-3 fixed top-5 right-5 z-50">
            <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
              <SheetTrigger asChild>
                <button onClick={toggleSheet} aria-label="Open Menu">
                  <Menu className="h-6 w-6" />
                </button>
              </SheetTrigger>
              <SheetContent side="right" className="w-10/12">
                <SheetHeader className="flex flex-row items-center gap-5" id="mobile-nav-header">
                  <SheetTitle className="text-c1 font-black text-2xl mb-0 mt-0">Menu</SheetTitle>
                  {config.languageSelector && <LangSelector />}
                </SheetHeader>
                <div className="mt-4">
                  {menuItems.header.map((item, index) => (
                    <React.Fragment key={index}>
                      <div
                        className="my-2 stagger"
                        style={{ animationDelay: `${index * 150}ms` }}
                      >
                        {item.dropdown ? (
                          <>
                            <button
                              className="w-full py-2 text-left flex items-center justify-between font-bold text-gray-600"
                              onClick={() => handleToggleDropdown(index)}
                            >
                              {item.label}
                              <ChevronDownIcon className="h-4 w-4" />
                            </button>
                            {openDropdown === index && (
                              <div className="mt-2 pl-4">
                                {item.items.map((subItem, subIndex) => (
                                  <Link
                                    key={subIndex}
                                    to={subItem.to}
                                    className="flex py-1 text-gray-600"
                                    onClick={() => setIsSheetOpen(false)}
                                  >
                                    {subItem.label}
                                  </Link>
                                ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <Link
                            to={item.to}
                            className="block py-2 text-gray-600 font-bold"
                            onClick={() => setIsSheetOpen(false)}
                          >
                            {item.label}
                          </Link>
                        )}
                      </div>
                      <hr />
                    </React.Fragment>
                  ))}
                </div>
              </SheetContent>
            </Sheet>
          </nav>

          <section className="relative z-10 py-10">
            <div>
              <div className="space-y-6 hidden sm:block">
                <h1 className="text-end font-bold leading-tight text-white">
                  {heading} {isHomePage ? <><br/>{description}</> : null}
                </h1>
                <h2 className="text-c5 text-end" dangerouslySetInnerHTML={{ __html: pageDesc }} />
              </div>
              <div className="relative rounded-lg" />
            </div>
          </section>
        </div>

      </div>
    </section>
  );
}
